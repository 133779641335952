<template>
<Dialog v-model:visible="visible" modal :header="setting.Key" :style="{ width: '50vw' }" maximizable>
    <!-- Description -->
    <p class="">{{ setting.Description }}</p>
    <DataTable :value="values" dataKey="Id" :loading="loading"
               v-model:editingRows="editingRows" editMode="row" @row-edit-save="onRowEditSave" tableClass="editable-cells-table"
               stripedRows scrollable resizableColumns show-gridlines columnResizeMode="fit">
        <Column field="Value" header="Value" style="width: 20%">
            <template #editor="{ data, field }">
                <InputText v-model="data[field]" />
            </template>
        </Column>
        <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center"/>
        <Column value="Delete" style="width: 10%;" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                    <i class="pi pi-trash" @click="deleteSetting(data)"/>
                </div>
            </template>
        </Column>
    </DataTable>

    <p class="mt-3 font-bold">
        Add a new value for this setting.
    </p>
    <div>
        <InputText class="w-full" v-model="newEntry" />
        <Button class="w-full mt-2" label="Add" @click="addNewEntry" />
    </div>
</Dialog>
</template>

<script>
export default {
    name: "SettingsValueView",

    data() {
        return {
            visible: false,
            loading: false,
            setting: {
                Key: "",
                Description: "",
            },
            values: [],
            editingRows: [],
            newEntry: ""
        }
    },

    methods: {
        init() {
            this.visible = false;
            this.setting = null;
            this.values = {};
        },

        show(setting) {
            this.init();
            this.setting = setting;
            this.getSettingValues();
            this.visible = true;
        },

        getSettingValues() {
            this.loading = true;
            this.$factory.settings.getSystemValues(this.setting.Id).then(data => {
                this.values = data;
                this.loading = false;
            })
        },

        onRowEditSave(event) {
            this.$factory.settings.updateSystemValue(event.newData.Id, event.newData.Value).then(() => {
                this.$emitter.emit("good-toast", "Setting updated successfully.");
                this.getSettingValues();
            })
        },

        addNewEntry() {
            this.$factory.settings.newSystemValue(this.setting.Id, this.newEntry).then(() => {
                this.$emitter.emit("good-toast", "Setting added successfully.");
                this.newEntry = "";
                this.getSettingValues();
            })
        },

        deleteSetting(data) {
            console.debug(data);
            this.$factory.settings.deleteSystemValue(data.Id).then(() => {
                this.$emitter.emit("good-toast", "Setting deleted successfully.");
                this.getSettingValues();
            })
        },
    },
}
</script>

<style scoped>

</style>
