<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="settings" dataKey="name"
                               v-model:selection="selectedSetting" selectionMode="single" @row-select="viewSetting"
                               stripedRows scrollable resizableColumns columnResizeMode="fit" show-gridlines>
                        <Column field="Key" header="Name" style="width: 20%"/>
                        <Column field="Description" header="Description"/>
                    </DataTable>
                </div>
            </div>

        </div>
    </div>
</div>

<SettingsValueView ref="SettingsValueView"/>

</template>

<script>
import SettingsValueView from "@/components/modals/SettingsValueView";

export default {
    name: "SystemSettings",
    components: {
        SettingsValueView,
    },

    data() {
        return {
            settings: [],
            selectedSetting: null,
        }
    },

    mounted() {
        this.getSettingKeys();
    },

    methods: {
        getSettingKeys() {
            this.$factory.settings.getSystemKeys().then(data => {
                this.settings = data;
            })
        },

        viewSetting($event) {
            this.selectedSetting = null;
            this.$refs.SettingsValueView.show($event.data);
        },
    },
}
</script>

<style scoped>

</style>
